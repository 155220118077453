<template>
	<div class="page-content assign-users">
		<div class="assignment border-end pe-2">
			<h3 class="text-center">{{ $t('timeLimits') }}</h3>
			<div class="row mx-0">
				<label class="col-form-label col">{{ $t('timeLimitStart') }}</label>
				<date-input :disabled="!canEditAssignment" class="col-6" v-model:date="startedAt" show-now-button />
			</div>
			<div class="row mt-3 mx-0 position-relative">
				<label class="col-form-label col">{{ $t('timeLimitEnd') }}</label>
				<date-input
					:disabled="!canEditAssignment"
					class="col-6"
					:class="{ 'is-invalid': v$.endedAt.$error }"
					v-model:date="endedAt"
				/>
				<div class="invalid-tooltip">
					{{ v$.endedAt.$errors[0]?.$message }}
				</div>
			</div>
			<h3 class="text-center mt-3">{{ $t('assignment') }}</h3>
			<div class="assignment-select">
				<div class="input-group position-relative">
					<label
						for="select-assignment"
						class="form-control"
						:class="{ 'is-invalid': v$.selectedAssignment.$error }"
						>{{ (assignment && assignment.name) || $t('selectAssignment') }}</label
					>
					<button
						id="select-assignment"
						type="button"
						class="btn btn-outline-secondary dropdown-toggle"
						@click.stop="toggleAssignmentList()"
						:disabled="!canEditAssignment"
					></button>
					<div class="invalid-tooltip">
						{{ v$.selectedAssignment.$error && $t(`validations.${errMsg('selectedAssignment')}`) }}
					</div>
					<div
						v-show="isAssignmentListVisible"
						v-click-outside="() => toggleAssignmentList(false)"
						class="list-group shadow-sm assignment-list"
					>
						<div
							v-for="assignment in sortedAssignments"
							:key="assignment.id"
							class="list-group-item list-group-item-action position-relative"
						>
							<span class="assignment-name">{{ assignment.name }}</span>
							<tag-list class="mt-1" :tags="assignment.tags" />
							<button
								type="button"
								class="assignment-list-button"
								@click="selectAssignment(assignment)"
							/>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!!assignment" class="assignment-data">
				<div v-if="showEndedAt" class="d-flex my-3">
					<label class="col-form-label me-2 text-nowrap">{{ $t('accessExpired') }}:</label>
					<date-input :model-value="assignment.endedAt" disabled />
				</div>
				<div class="d-flex">
					<label class="col-form-label me-2 text-nowrap">{{ $t('ordered') }}:</label>
					<input
						type="text"
						readonly
						class="form-control-plaintext"
						:value="$t(assignment.ordered ? 'orderSeq' : 'orderArb')"
					/>
				</div>
				<div class="border-top">
					<h5>{{ $t('tasks') }}</h5>
					<ol type="I">
						<li v-for="part in assignment.parts" :key="part.id">
							<div>
								<div class="d-flex">
									<label class="col-form-label me-2 text-nowrap">{{ $t('ordered') }}:</label>
									<input
										type="text"
										readonly
										class="form-control-plaintext"
										:value="$t(part.ordered ? 'orderSeq' : 'orderArb')"
									/>
								</div>
								<ol>
									<li v-for="task in part.tasks">
										<div>{{ task.name }}</div>
										<div class="d-flex">
											<label class="col-form-label me-2 text-nowrap">{{
												$t('passingGrade')
											}}</label>
											<input
												type="text"
												class="form-control form-control-sm"
												:value="passingGrades[part.id][task.id]"
												@input="setGradeOverride(part, task, $event)"
												:disabled="!canEditAssignment"
											/>
										</div>
									</li>
								</ol>
							</div>
						</li>
					</ol>
				</div>
			</div>
		</div>
		<div class="user-header ps-2">
			<h3 class="text-center">{{ $t('allUsers') }}</h3>
			<div class="d-flex">
				<div class="pb-2 pe-2">
					<label>{{ $t('tagsFilter') }}</label>
					<tag-list :all-tags="tagsList" v-model:tags="selectedTags" editable class="search" />
				</div>
				<div>
					<label>{{ $t('searchUser') }}</label>
					<input class="form-control form-control-sm" type="text" v-model="searchUser" />
				</div>
			</div>
		</div>
		<div class="user-list ps-2 pb-2 border-bottom">
			<draggable
				class="users"
				v-model="displayedUsers"
				:group="{ name: 'users', pull: 'clone', put: false }"
				:sort="false"
				item-key="id"
				:move="checkMoveTarget"
			>
				<template #item="{ element: user }">
					<div class="card">
						<div class="card-header">{{ user.email }}</div>
						<div class="card-body">
							<div>{{ user.email }}</div>
							<tag-list :tags="user.tags" />
						</div>
					</div>
				</template>
			</draggable>
		</div>
		<div class="selected-users ps-2 pt-2">
			<h3 class="text-center">{{ $t('selectedUsers') }}</h3>
			<draggable
				class="users"
				:class="{ 'is-invalid': v$.selectedUsers.$error }"
				v-model="selectedUsers"
				group="users"
				:sort="false"
				item-key="id"
				:data-title="
					v$.selectedUsers.$error ? $t(`validations.${errMsg('selectedUsers')}`) : $t('dragUsersHere')
				"
				:disabled="!canEditAssignment"
			>
				<template #item="{ element: user }">
					<div class="card">
						<div class="card-header d-flex align-items-center">
							<span>{{ user.email }}</span
							><button type="button" class="btn-close ms-auto" @click="removeFromSelected(user)" />
						</div>
						<div class="card-body">
							<div>{{ user.email }}</div>
							<tag-list :tags="user.tags" />
						</div>
					</div>
				</template>
			</draggable>
			<div class="control-button">
				<button
					type="button"
					class="btn btn-danger"
					v-if="!isNew && canDeleteAssignmentForUser(authorId)"
					@click.stop="isDeleteConfirmModalVisible = true"
				>
					{{ $t('deleteAssignment') }}
				</button>
				<button v-if="canEditAssignment" type="button" class="btn btn-success" @click="assignUsers">
					{{ $t(this.isNew ? 'assignAssignment' : 'updateAssignment') }}
				</button>
			</div>
		</div>

		<modal v-model:show="isDeleteConfirmModalVisible" @confirm="deleteAssignment" />
	</div>
</template>

<style scoped lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
.assign-users {
	display: grid;
	grid-template-rows: max-content max-content 1fr;
	grid-template-columns: max-content 1fr;
	grid-template-areas: 'assignment userheader' 'assignment allusers' 'assignment selectedusers';

	padding-bottom: 2rem;
}

.assignment {
	grid-area: assignment;

	.assignment-list {
		position: absolute;
		top: calc(100% + 0.25rem);
		left: 0;
		right: 0;
		z-index: 10;

		.assignment-name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: block;
		}

		.assignment-list-button {
			display: block;
			position: absolute;
			width: 100%;
			top: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
		}
	}
}

.user-header {
	grid-area: userheader;
}

.user-list {
	grid-area: allusers;
	overflow: hidden;
	

	.users {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		gap: 0.5rem;

		padding-bottom: 0.5rem;

		.card {
			min-width: max-content;
		}
	}
}

.selected-users {
	grid-area: selectedusers;
	display: flex;
	flex-direction: column;

	.users {
		flex-grow: 1;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		gap: 0.5rem;
	}

	.users:empty {
		border: 3px dashed $border-color;
		border-radius: 3 * $border-radius;

		&:after {
			width: 100%;
			height: 100%;
			content: attr(data-title);
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: $border-color;
			font-size: 300%;
		}
	}

	.users.is-invalid:empty {
		border-color: $danger;
		&:after {
			color: $danger;
		}
	}

	.control-button {
		margin-top: 2rem;
		display: flex;
		justify-content: center;
		gap: 2rem;

		button {
			display: block;
			min-width: 33%;
		}
	}
}
</style>

<i18n locale="ru" src="@/locales/ru/views/assignment-users.json"></i18n>
<i18n locale="en" src="@/locales/en/views/assignment-users.json"></i18n>

<script>
import { mapState } from 'vuex';
import Draggable from 'vuedraggable';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import dayjs from 'dayjs';

import errorMessageMixin from '@/mixins/errorMessage';
import { assignmentForUserPermissionsMixin } from '@/mixins';
import { QueryError } from '@/errors';
import TagList from '@/components/TagList';
import DateInput from '@/components/inputs/DateInput';
import Modal from '@/components/assignment-users/ModalRemoveAssignmentForUsers';

import CREATE_QUERY from '@/queries/views/assignment-users/query-create.graphql';
import EDIT_QUERY from '@/queries/views/assignment-users/query-edit.graphql';
import ASSIGNMENT_QUERY from '@/queries/views/assignment-users/query-assignment.graphql';
import CREATE_MUTATION from '@/queries/views/assignment-users/mutation-create.graphql';
import EDIT_MUTATION from '@/queries/views/assignment-users/mutation-edit.graphql';
import DELETE_MUTATION from '@/queries/views/assignment-users/mutation-delete.graphql';

import { dateTimeMixin, toastMixin } from '@/mixins';

export default {
	props: {
		assignmentId: {
			type: String,
			default: null,
		},
		groupId: {
			type: String,
			default: null,
		},
	},
	mixins: [errorMessageMixin, assignmentForUserPermissionsMixin, dateTimeMixin, toastMixin],
	components: {
		TagList,
		Draggable,
		DateInput,
		Modal,
	},
	data() {
		const now = new Date().toISOString();
		return {
			authorId: null,
			availableAssignments: [],
			selectedAssignment: this.assignmentId,
			availableUsers: [],
			selectedUsers: [],
			assignment: null,
			isAssignmentListVisible: false,
			gradeOverrides: [],
			startedAt: now,
			endedAt: now,
			isDeleteConfirmModalVisible: false,
			searchUser: '',
			selectedTags: [],
		};
	},
	validations() {
		return {
			selectedAssignment: {
				required: helpers.withMessage('AssignmentNotSelected', required),
			},
			selectedUsers: {
				required: helpers.withMessage('UsersNotSelected', required),
			},
			endedAt: {
				required: helpers.withMessage('EndedAtIsRequired', required),
				EndedAtIsEarlyStartedAt: helpers.withMessage(this.$t('validations.EndedAtIsEarlyStartedAt'), () =>
					dayjs(this.endedAt).isAfter(this.startedAt)
				),
				EndedAtIsLaterEndedAt: helpers.withMessage(this.$t('validations.EndedAtIsLaterEndedAt'), () =>
					this.isSuperuser || this.isStaff ? true : dayjs(this.assignment?.endedAt).isAfter(this.endedAt)
				),
			},
		};
	},
	computed: {
		isNew() {
			return !this.groupId;
		},
		passingGrades() {
			if (this.assignment) {
				const overrides = this.gradeOverrides;
				return this.assignment.parts.reduce(function (grades, part) {
					grades[part.id] = part.tasks.reduce(function (taskGrades, task) {
						const override = overrides.find(ovr => ovr.task === task.id && ovr.part === part.id);
						taskGrades[task.id] = override?.grade ?? task.gradeOverride ?? task.passingGrade;
						return taskGrades;
					}, {});
					return grades;
				}, {});
			}
		},
		canEditAssignment() {
			return (
				(this.isNew && this.canAddAssignmentForUser) ||
				(!this.isNew && this.canChangeAssignmentForUser(this.authorId))
			);
		},
		showEndedAt() {
			return this.isSuperuser || this.isStaff || this.assignment?.structurallyOwnedByCompany ? false : true;
		},
		formatEndedAt() {
			const endedAt = this.assignment.endedAt;
			return endedAt === '0001-01-01' || endedAt === '9999-12-31'
				? this.$t('undefiendEndedAt')
				: this.formatDateTime(endedAt);
		},
		...mapState('auth', {
			isSuperuser: state => state.user.isSuperuser,
			isStaff: state => state.user.isStaff,
		}),
		tagsList() {
			return this.availableUsers.reduce(function (acc, user) {
				if (!user.tags?.length) {
					return acc;
				}
				const tags = user.tags.map(tag => ({ ...tag })).filter(tag => !acc.some(a => a.id === tag.id));
				acc.push(...tags);
				return acc;
			}, []);
		},
		displayedUsers() {
			const users = this.availableUsers;
			const search = this.searchUser.toLowerCase();
			const tags = this.selectedTags;
			return users.filter(function (user) {
				if (tags.length) {
					const hasAllTags = user.tags.length > 0 && tags.every(tag => user.tags.some(t => t.id === tag.id));
					if (!hasAllTags) {
						return false;
					}
				}
				if (search.length) {
					if (!user.email.toLowerCase().includes(search)) {
						return false;
					}
				}
				return true;
			});
		},
		sortedAssignments() {
			const assignments = [...this.availableAssignments];
			return assignments.sort((a, b) => a.name.localeCompare(b.name));
		},
	},
	methods: {
		toggleAssignmentList(value) {
			this.isAssignmentListVisible = value ?? !this.isAssignmentListVisible;
		},
		selectAssignment(assignment) {
			this.selectedAssignment = assignment.id;
			this.toggleAssignmentList(false);
		},
		setGradeOverride(part, task, ev) {
			const val = parseInt(ev.target.value, 10);
			const passingGrade = task.gradeOverride ?? task.passingGrade;
			if (Number.isNaN(val)) {
				return (ev.target.value = passingGrade);
			}
			const existing = this.gradeOverrides.find(ovr => ovr.part === part.id && ovr.task === task.id);
			if (existing) {
				if (passingGrade === val) {
					this.gradeOverrides = this.gradeOverrides.filter(ovr => ovr !== existing);
				} else {
					existing.grade = val;
				}
			} else {
				this.gradeOverrides.push({ part: part.id, task: task.id, grade: val });
			}
		},
		checkMoveTarget({ draggedContext: { element }, relatedContext: { list } }) {
			return !list.some(i => i.id === element.id);
		},
		removeFromSelected(user) {
			this.selectedUsers = this.selectedUsers.filter(u => u != user);
		},
		async assignUsers() {
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}
			try {
				const variables = {
					data: {
						userIds: this.selectedUsers.map(u => u.id),
						gradeOverrides: this.gradeOverrides,
						startedAt: this.startedAt,
						endedAt: this.endedAt,
					},
				};
				if (this.isNew) {
					variables.data.assignmentId = this.selectedAssignment;
				} else {
					variables.id = this.groupId;
				}
				const { data } = await this.$apollo.mutate({
					mutation: this.isNew ? CREATE_MUTATION : EDIT_MUTATION,
					variables,
				});
				if (data.mutation.success) {
					this.showToast('successText', 'success', 'success');
				} else {
					throw new QueryError(data.mutation.error);
				}
			} catch (err) {
				let errorMsg;
				if (err instanceof QueryError) {
					errorMsg = `errors.${err.cause.type}`;
					if (err.cause.type === 'ValueOutOfBoundsError') {
						if (err.cause.field === 'started_at') {
							errorMsg += '.startedAt';
						}
						if (err.cause.field === 'ended_at') {
							const endedAt = this.formatDateTime(err.cause.value);
							errorMsg = this.$t(errorMsg + '.endedAt', { endedAt });
						}
					}
				} else {
					errorMsg = 'errors.FallbackError';
				}
				this.showToast(errorMsg, 'error', 'danger');
			} finally {
				this.v$.$reset();
			}
		},
		async deleteAssignment() {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: DELETE_MUTATION,
					variables: { id: this.groupId },
				});
				if (data.mutation.success) {
					this.$router.push({ name: 'Assignments' });
				} else {
					throw new QueryError(data.mutation.error);
				}
			} catch (err) {
				this.showToast('errors.FallbackError', 'error', 'danger');
			}
		},
	},
	apollo: {
		general() {
			return {
				query: this.isNew ? CREATE_QUERY : EDIT_QUERY,
				manual: true,
				result({ loading, data }) {
					if (!loading) {
						this.availableAssignments = data.myCompany.assignments;
						this.availableUsers = data.myCompany.users;
						if (!this.isNew && !data.assignmentGroup) {
							this.$router.push({ name: 'AssignmentUsers' });
						}
						if (!this.isNew) {
							this.selectedUsers = data.myCompany.users.filter(function (usr) {
								return data.assignmentGroup.users.some(u => u.id === usr.id);
							});
							this.authorId = data.assignmentGroup.author.id;
							this.assignment = data.assignmentGroup.assignment;
							this.selectedAssignment = this.assignment.id;
							this.gradeOverrides = data.assignmentGroup.gradeOverrides.map(({ part, task, grade }) => ({
								part,
								task,
								grade,
							}));
							this.startedAt = data.assignmentGroup.startedAt;
							this.endedAt = data.assignmentGroup.endedAt;
						}
					}
				},
				variables() {
					return { groupId: this.groupId };
				},
			};
		},
		assignment: {
			query: ASSIGNMENT_QUERY,
			variables() {
				return { id: this.selectedAssignment };
			},
			skip() {
				return !this.selectedAssignment || this.selectedAssignment === this.assignment?.id;
			},
		},
	},
	setup() {
		return { v$: useVuelidate() };
	},
};
</script>
