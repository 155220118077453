<template>
	<modal v-model:show="localShow">
		<template #header>{{ $t('title') }}</template>
		<template #body>
			<div class="position-relative" :class="{ loading }">
				<p>{{ $t('text') }}</p>
				<table class="table table-bordered caption-top">
					<caption>
						{{
							$t('sampleTable')
						}}
					</caption>
					<tbody>
						<tr class="bg-white">
							<td>{{ $t('sampleEmail') }}</td>
							<td>{{ $t('samplePassword') }}</td>
							<td>{{ $t('samplePin') }}</td>
							<td>{{ $t('sampleNotes') }}</td>
						</tr>
						<tr class="text-muted">
							<td>{{ $t('emailNotes') }}</td>
							<td>{{ $t('passwordNotes') }}</td>
							<td>{{ $t('pinNotes') }}</td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<file-input accept=".csv" v-model:file="file" />
				<p class="alert alert-danger mt-3" v-show="users.length + parsedUsers.length > userLimit">
					{{ $t('userLimitAlert') }}
				</p>
				<table v-show="parsedUsers.length" class="table table-striped caption-top">
					<caption>
						{{
							$t('usersToAdd')
						}}
					</caption>
					<thead>
						<tr>
							<th>#</th>
							<th>{{ $t('email') }}</th>
							<th>{{ $t('password') }}</th>
							<th>{{ $t('pin') }}</th>
							<th>{{ $t('notes') }}</th>
						</tr>
					</thead>
					<tbody>
						<parsed-table-line
							v-for="user in parsedUsers"
							:key="user.line"
							:user="user"
							:validations="userValidations"
						/>
					</tbody>
				</table>

				<div class="alert alert-danger mt-2" v-show="parseFailed">
					{{ $t('fileParseFailed') }}
				</div>

				<div class="overlay">
					<div class="spinner-border"></div>
				</div>
			</div>
		</template>
		<template #footer>
			<button type="button" class="btn btn-outline-secondary w-25" :disabled="loading" @click="localShow = false">
				{{ $t('cancel') }}
			</button>
			<button type="button" class="btn btn-success w-25" :disabled="submitDisabled" @click="createUsers">
				{{ $t('submit') }}
			</button>
		</template>
	</modal>
</template>

<style scoped lang="scss">
.overlay {
	display: none;
}
.loading > :not(.overlay) {
	opacity: 0.25;
}
.loading > .overlay {
	display: block;

	position: absolute;
	top: -1rem;
	left: -1rem;
	bottom: -1rem;
	right: -1rem;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(0, 0, 0, 0.05);

	.spinner-border {
		width: 5rem;
		height: 5rem;
	}
}
</style>

<i18n locale="ru" src="@/locales/ru/components/users-view/load-from-file-modal.json"></i18n>
<i18n locale="en" src="@/locales/en/components/users-view/load-from-file-modal.json"></i18n>

<script>
import { parse } from 'csv-parse/browser/esm/sync';
import useVuelidate from '@vuelidate/core';
import { required, email, or, not, sameAs, minLength, maxLength, numeric, helpers } from '@vuelidate/validators';

import { isInArray, sameAsOther } from '@/utils/validators';

import { modalMixin } from '@/mixins';
import Modal from '@/components/modals/VrModal';
import FileInput from '@/components/inputs/FileInput';

import ParsedTableLine from './ParsedTableLine';

export default {
	components: {
		Modal,
		FileInput,
		ParsedTableLine,
	},
	mixins: [modalMixin],
	props: {
		users: {
			type: Array,
			default: () => [],
		},
		userLimit: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			file: null,
			parsedUsers: [],
			parseFailed: false,
			loading: false,
		};
	},
	computed: {
		userValidations() {
			const emails = this.users.map(u => u.email);

			return {
				email: {
					required: helpers.withMessage('NoEmailError', required),
					email: helpers.withMessage('EmailFormatError', email),
					isUnique: helpers.withMessage('EmailExistsError', not(isInArray(emails))),
				},
				password: {
					// TODO: load minLength from server
					minLength: helpers.withMessage('PasswordTooShortError', minLength(8)),
					notSameAsEmail: helpers.withMessage('SameAsEmailError', not(sameAsOther('email'))),
					notSameAsUsername: helpers.withMessage('SameAsUsernameError', not(sameAsOther('username'))),
				},
				pin: {
					// TODO: load length from server
					minLength: helpers.withMessage('PinLengthError', minLength(4)),
					maxLength: helpers.withMessage('PinLengthError', maxLength(4)),
					format: helpers.withMessage('PinFormatError', numeric),
				},
			};
		},
		submitDisabled() {
			return (
				!this.parsedUsers.length ||
				this.v.$error ||
				this.loading ||
				this.parsedUsers.length + this.users.length > this.userLimit
			);
		},
	},
	emits: ['create-users'],
	methods: {
		loadFile(file) {
			this.fileReader.readAsText(file);
		},
		parseFile() {
			try {
				this.parseFailed = false;
				this.parsedUsers = [];
				const records = parse(this.fileReader.result, { delimiter: [',', ';'] });
				this.parsedUsers = records.map(function (rec, i) {
					if (!rec.length) {
						return { line: i + 1, failed: true };
					} else {
						const [email, password, pin, notes] = rec.map(i => (i ? i : null));
						return { line: i + 1, failed: false, email, notes, password, pin };
					}
				});
				this.$nextTick(() => this.v.$touch());
			} catch (err) {
				this.parseFailed = true;
			}
		},
		createUsers() {
			const users = this.parsedUsers.map(u => ({
				email: u.email,
				notes: u.notes,
				password: u.password,
				pin: u.pin,
			}));
			this.$emit('create-users', users);
			this.loading = true;
		},
	},
	setup() {
		return { v: useVuelidate() };
	},
	created() {
		this.fileReader = new FileReader();
		this.fileReader.addEventListener('load', this.parseFile);
	},
	beforeDestroy() {
		this.fileReader.removeEventListener('load', this.parseFile);
	},
	watch: {
		localShow(val) {
			if (!val) {
				this.parseFailed = false;
				this.parsedUsers = [];
				this.file = null;
				this.loading = false;
			}
		},
		file(val) {
			if (val) {
				this.loadFile(val);
			}
		},
	},
};
</script>
