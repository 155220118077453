<template>
	<modal v-model:show="localShow">
		<template #header>{{ $t(title) }}</template>
		<template #body>
			<div v-show="!isSingle" class="form-group alert alert-warning">
				<p class="mb-0">{{ $t('warning') }}</p>
			</div>
			<div v-show="isSingle" class="form-group">
				<input
					:disabled="!canEditUser"
					type="text"
					class="form-control"
					:class="{ 'is-invalid': v.email?.$error }"
					v-model="email"
					name="email"
				/>
				<label class="col-form-label">{{ $t('email') }}</label>
				<div class="invalid-tooltip">{{ v.email?.$error && $t(`errors.${v.email.$errors[0]?.$message}`) }}</div>
			</div>
			<div v-show="isSingle" class="form-group">
				<input
					name="pin"
					:disabled="!canEditUser"
					type="text"
					class="form-control"
					:class="{ 'is-invalid': v.pin?.$error }"
					v-model="pin"
				/>
				<label class="col-form-label">{{ $t('pin') }}</label>
				<div class="invalid-tooltip">{{ v.pin?.$error && $t(`errors.${v.pin.$errors[0]?.$message}`) }}</div>
			</div>
			<div v-show="isSingle && isNew" class="form-group">
				<password-input
					type="text"
					:v-error="v.password?.$error"
					:v-error-msg="(v.password?.$error && $t(`errors.${v.password.$errors[0]?.$message}`)) || ''"
					v-model:password="password"
				/>
				<label class="col-form-label">{{ $t('password') }}</label>
			</div>
			<div v-show="isSingle && isNew" class="form-group">
				<password-input
					type="text"
					:v-error="v.passwordRepeat?.$error"
					:v-error-msg="
						(v.passwordRepeat?.$error && $t(`errors.${v.passwordRepeat.$errors[0]?.$message}`)) || ''
					"
					v-model:password="passwordRepeat"
				/>
				<label class="col-form-label">{{ $t('passwordRepeat') }}</label>
			</div>
			<div class="form-group">
				<label class="col-form-label align-self-start pt-0">{{ $t('roles') }}</label>
				<div class="roles-list">
					<div class="form-check form-check-inline" v-for="role in roles" :key="role.id">
						<input
							:disabled="!canChangeUserRoles"
							:id="`role-check-${role.id}`"
							class="form-check-input"
							type="checkbox"
							:value="role.id"
							v-model="userRoles"
						/>
						<label :for="`role-check-${role.id}`" class="form-check-label">{{ role.name }}</label>
					</div>
					<p v-show="!isSingle" class="form-text w-100">{{ $t('rolesHint') }}</p>
				</div>
			</div>
			<div class="form-group">
				<label class="col-form-label">{{ $t('tags') }}</label>
				<div>
					<tag-list
						v-if="show"
						v-model:tags="tags"
						v-model:all-tags="localAllTags"
						editable
						:allow-create="canAddTag"
						:allow-delete="canDeleteTag"
						short
						top
					/>
					<p v-show="!isSingle" class="form-text w-100">{{ $t('tagsHint') }}</p>
				</div>
			</div>
			<div v-show="isSingle" class="form-group">
				<textarea class="form-control" v-model="notes" name="notes"></textarea>
				<label class="col-from-label">{{ $t('notes') }}</label>
			</div>
		</template>
		<template #footer>
			<button class="btn btn-outline-secondary" @click="localShow = false">{{ $t('cancel') }}</button>
			<button class="btn btn-success" @click="submit">{{ $t(isNew ? 'confirmCreate' : 'confirmEdit') }}</button>
		</template>
	</modal>
</template>

<style scoped lang="scss">
.form-group + .form-group {
	margin-top: 1rem;
}

.form-group {
	display: flex;
	gap: 1rem;
	position: relative;

	label {
		order: -1;
		width: 15%;
		flex-shrink: 0;
	}

	.invalid-tooltip {
		left: calc(15% + 1rem);
	}

	.form-text {
		margin-bottom: 0;
	}
}

.roles-list {
	display: flex;
	align-items: center;
	column-gap: 1rem;
	flex-wrap: wrap;
}
</style>

<i18n locale="ru" src="@/locales/ru/components/users-view/edit-user-modal.json"></i18n>
<i18n locale="en" src="@/locales/en/components/users-view/edit-user-modal.json"></i18n>

<script>
import useVuelidate from '@vuelidate/core';
import { email, helpers, maxLength, minLength, numeric, sameAs, not, required } from '@vuelidate/validators';
import { isInArray } from '@/utils/validators';
import { modalMixin, userPermissionsMixin, tagPermissionsMixin } from '@/mixins';

import PasswordInput from '@/components/inputs/PasswordInput';
import TagList from '@/components/TagList';

export default {
	mixins: [modalMixin, userPermissionsMixin, tagPermissionsMixin],
	emits: ['confirm', 'update:allTags'],
	props: {
		users: {
			type: Array,
			default: () => [],
		},
		allUsers: {
			type: Array,
			default: () => [],
		},
		roles: {
			type: Array,
			default: () => [],
		},
		defaultRole: {
			type: Object,
			default: () => ({}),
		},
		allTags: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		PasswordInput,
		TagList,
	},
	data() {
		return {
			existingEmails: [],
			username: null,
			email: null,
			pin: null,
			password: '',
			passwordRepeat: '',
			userRoles: [],
			notes: null,
			tags: [],
		};
	},
	validations() {
		if (!this.isSingle) {
			return {};
		}

		const validations = {
			email: {
				format: helpers.withMessage('EmailFormatError', email),
				occupied: helpers.withMessage('EmailExistsError', not(isInArray(this.existingEmails))),
				required: helpers.withMessage('EmailRequiredError', required),
			},
			pin: {
				required: helpers.withMessage('PinRequiredError', required),
				minLength: helpers.withMessage('PinLengthError', minLength(4)),
				maxLength: helpers.withMessage('PinLengthError', maxLength(4)),
				format: helpers.withMessage('PinFormatError', numeric),
			},
		};

		if (this.isNew) {
			validations.password = {
				length: helpers.withMessage('PasswordTooShortError', minLength(8)),
				sameAsUsername: helpers.withMessage('SameAsUsernameError', not(sameAs(this.username))),
				sameAsEmail: helpers.withMessage('SameAsEmailError', not(sameAs(this.email))),
			};
			validations.passwordRepeat = {
				repeat: helpers.withMessage('PasswordMismatchError', sameAs(this.password)),
			};
		}

		return validations;
	},
	computed: {
		isSingle() {
			return this.users.length < 2;
		},
		currentUser() {
			return this.isSingle ? this.users[0] : null;
		},
		isNew() {
			return this.isSingle && !this.currentUser?.id;
		},
		title() {
			if (this.isNew) {
				return 'titleCreate';
			}
			if (this.isSingle) {
				return 'titleEditSingle';
			}
			return 'titleEditMultiple';
		},
		canEditUser() {
			if (this.isNew) {
				return true;
			}
			return this.canChangeUser;
		},
		localAllTags: {
			set(v) {
				this.$emit('update:allTags', v);
			},
			get() {
				return this.allTags;
			},
		},
	},
	methods: {
		loadUserData() {
			this.notes = this.currentUser?.notes;
			this.email = this.currentUser?.email;
			this.pin = this.currentUser?.pin;
			if (this.isNew) {
				this.userRoles = [this.defaultRole.id];
			} else {
				this.userRoles = this.isSingle ? this.currentUser.roles.map(r => r.id) : [];
			}
			this.tags = this.currentUser?.tags.slice(0) ?? [];

			const users = this.users;
			const emails = this.allUsers.reduce(function (acc, usr) {
				if (!users.some(u => u.id === usr.id)) {
					acc.push(usr.email);
				}
				return acc;
			}, []);
			// this is a bit of black magic to preserve these arrays for validators
			this.existingEmails.splice(0, Infinity, ...emails);
		},
		submit() {
			this.v.$touch();
			if (this.v.$error) {
				return;
			}
			const data = { roles: this.userRoles, tags: this.tags.map(t => t.id) };
			if (this.isSingle) {
				data.notes = this.notes;
				data.email = this.email;
				data.pin = this.pin;
			}
			if (this.isNew) {
				data.password = this.password;
			}
			this.$emit('confirm', data);
		},
	},
	setup() {
		return { v: useVuelidate() };
	},
	watch: {
		localShow(v) {
			if (v) {
				this.loadUserData();
				this.v.$reset();
			} else {
				if (this.isNew === true) {
					this.email = null;
					this.username = null;
					this.pin = null;
					this.password = '';
					this.passwordRepeat = '';
					this.userRoles = [];
					this.notes = null;
					this.tags = [];
				}
			}
		},
	},
};
</script>
