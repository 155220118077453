<template>
	<div class="page-content">
		<spinner :show="$apollo.loading" />
		<div class="controls">
			<div class="d-flex align-items-end gap-3">
				<dropdown-list
					icon="fa-solid fa-filter"
					:list="filterDropdownItems"
					v-model:selection="filters"
					:i18n="filtersI18n"
					class="align-bottom"
				/>

				<dropdown-list
					icon="fa fa-user-group"
					:list="rolesList"
					v-model:selection="selectedRoles"
					align="right"
				/>

			</div>
			<button v-show="canEditUsers" class="btn btn-info" @click.stop="openEditModal(false)">
				{{ $t(selectedUsers.length === 1 ? 'editOneUser' : 'editManyUsers') }}
			</button>
			<button v-show="canArchiveUsers" class="btn btn-secondary" @click="archiveUsers">
				{{ $t(selectedUsers.length === 1 ? 'archiveOneUser' : 'archiveManyUsers') }}
			</button>
			<button v-show="canRestoreUsers" class="btn btn-success" @click="restoreUsers">
				{{ $t(selectedUsers.length === 1 ? 'restoreOneUser' : 'restoreManyUsers') }}
			</button>
			<button v-show="canDeleteUsers" class="btn btn-danger" @click.stop="isDeleteUsersModalVisible = true">
				{{ $t(selectedUsers.length === 1 ? 'deleteOneUser' : 'deleteManyUsers') }}
			</button>
		</div>
		<div>
			<label>{{ $t('tagsFilter') }}</label>
			<tag-list :all-tags="tagsList" v-model:tags="selectedTags" editable />
		</div>
		<table class="table table-striped table-hover">
			<thead>
				<tr>
					<th></th>
					<th>#</th>
					<th>
						<button class="table-header-sort" @click="cycleSort('email')">
							<span>{{ $t('email') }}</span>
							<fa-icon :icon="getSortIcon('email')" />
						</button>
					</th>
					<th>
						<button class="table-header-sort" @click="cycleSort('status')">
							<span>{{ $t('status') }}</span>
							<fa-icon :icon="getSortIcon('status')" />
						</button>
					</th>
					<th>
						<button class="table-header-sort" @click="cycleSort('createdAt')">
							<span>{{ $t('createdAt') }}</span>
							<fa-icon :icon="getSortIcon('createdAt')" />
						</button>
					</th>
					<th>{{ $t('pin') }}</th>
					<th>{{ $t('roles') }}</th>
					<th>{{ $t('tags') }}</th>
					<th>{{ $t('notes') }}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					class="table-line"
					:class="{ 'table-info': selectedUsers.includes(user) }"
					v-for="user in displayedUsers"
					:key="user.id"
				>
					<td>
						<input
							type="checkbox"
							:id="`line-${user.id}`"
							class="form-check-input"
							:checked="selectedUsers.includes(user)"
						/>
						<button
							class="table-line-overlay"
							@click.exact="selectUser(user)"
							@click.shift.exact="selectAll(user)"
						/>
					</td>
					<th>{{ userNumber(user) }}</th>
					<td class="email">
						<fa-icon icon="fa fa-crown" v-if="user.isOwner" />&nbsp;<span>{{ user.email }}</span>
					</td>
					<td>{{ $t(`statuses.${user.status}`) }}</td>
					<td>{{ formatDateTime(user.createdAt) }}</td>
					<td>{{ user.pin }}</td>
					<td>
						{{ user.roles.map(r => r.name).join(', ') }}
					</td>
					<td>
						<tag-list :tags="user.tags" />
					</td>
					<td>
						<span class="limit-length">{{ user.notes }}</span>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="d-flex align-items-center">
			<pagination :items-on-page="itemsPerPage" :items-total="filteredUsers.length" @changePage="changePage" />
			<div
				class="alert py-2 mb-0"
				:class="{ 'alert-info': userCount < userLimit, 'alert-danger': userCount >= userLimit }"
			>
				{{ $t('userLimitMessage', { userCount, userLimit }) }}
			</div>
			<button
				v-if="canAddUser && userCount < userLimit"
				class="btn btn-success ms-auto"
				@click.stop="openEditModal(true)"
			>
				{{ $t('addOneUser') }}
			</button>
			<button
				v-if="canAddUser && userCount < userLimit"
				class="btn btn-success ms-2"
				@click.stop="isLoadFromFileModalVisible = true"
			>
				{{ $t('addManyUsers') }}
			</button>
		</div>

		<load-from-file-modal
			v-model:show="isLoadFromFileModalVisible"
			:users="users"
			:user-limit="userLimit"
			@create-users="bulkCreateUsers"
		/>

		<delete-users-modal v-model:show="isDeleteUsersModalVisible" :users="selectedUsers" @confirm="deleteUsers" />

		<edit-user-modal
			v-model:show="isEditUserModalVisible"
			:users="selectedUsers"
			:all-users="users"
			:roles="roles"
			:default-role="defaultRole"
			v-model:allTags="allTags"
			@confirm="editUsers"
		/>
	</div>
</template>

<style scoped lang="scss">
.controls {
	display: flex;
	justify-content: space-between;
}
.table th,
.table td.email {
	white-space: nowrap;
}
.table-header-sort {
	border: none;
	outline: none;
	background-color: transparent;
	box-shadow: none;
	font-weight: bold;

	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
}
.table-line {
	position: relative;

	.table-line-overlay {
		display: block;
		position: absolute;
		z-index: 3;
		opacity: 0;
		width: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		cursor: pointer;
	}

	.limit-length {
		display: inline-block;
		max-width: 30ch;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
</style>

<i18n locale="ru" src="@/locales/ru/views/users.json"></i18n>
<i18n locale="en" src="@/locales/en/views/users.json"></i18n>

<script>
import { dateTimeMixin, toastMixin, userPermissionsMixin } from '@/mixins';
import USERS_QUERY from '@/queries/views/users/query-users.graphql';
import BULK_CREATE_MUTATION from '@/queries/views/users/mutation-bulk-create.graphql';
import BULK_DELETE_MUTATION from '@/queries/views/users/mutation-bulk-delete.graphql';
import BULK_UPDATE_MUTATION from '@/queries/views/users/mutation-bulk-update.graphql';
import BULK_RESTORE_MUTATION from '@/queries/views/users/mutation-bulk-restore.graphql';
import UPDATE_MUTATION from '@/queries/views/users/mutation-update.graphql';
import { QueryError } from '@/errors';
import Pagination from '@/components/Pagination';
import Dropdown from '@/components/dropdowns/Dropdown';
import DropdownList from '@/components/dropdowns/DropdownList';
import LoadFromFileModal from '@/components/users-view/LoadFromFileModal';
import DeleteUsersModal from '@/components/users-view/DeleteUsersModal';
import EditUserModal from '@/components/users-view/EditUserModal';
import Spinner from '@/components/Spinner.vue';
import TagList from '@/components/TagList';

export default {
	components: {
		Pagination,
		Dropdown,
		DropdownList,
		LoadFromFileModal,
		DeleteUsersModal,
		EditUserModal,
		Spinner,
		TagList,
	},
	mixins: [dateTimeMixin, toastMixin, userPermissionsMixin],
	data() {
		return {
			users: [],
			userLimit: 1,
			defaultRole: null,
			roles: [],
			userTags: [],
			allTags: [],
			isLoadFromFileModalVisible: false,
			selectedUsers: [],
			lastSelectedUser: null,
			sortBy: {
				field: null,
				direction: null,
			},
			filterDropdownItems: ['showActive', 'showInactive', 'showArchived'],
			filters: {
				showActive: true,
				showInactive: true,
				showArchived: false,
			},
			selectedRoles: [],
			selectedTags: [],
			isDeleteUsersModalVisible: false,
			isEditUserModalVisible: false,
			itemsPerPage: 10,
			displayStart: 0,
			displayEnd: 10, // must be equal to itemsPerPage
		};
	},
	computed: {
		userCount() {
			return this.users.filter(u => !u.isArchived).length;
		},
		filteredUsers() {
			const { field, direction } = this.sortBy;
			const mult = direction === 'desc' ? -1 : 1;
			const filters = this.filters;
			const roles = this.selectedRoles;
			const selectedTags = this.selectedTags;

			const statusScoreFn = this.userStatusScore;

			return this.users
				.filter(function (user) {
					if (!filters.showActive && user.isActive) {
						return false;
					}
					if (!filters.showInactive && user.isInactive) {
						return false;
					}
					if (!filters.showArchived && user.isArchived) {
						return false;
					}
					const userRoles = user.roles.map(r => r.id);
					if (roles.length && roles.some(r => !userRoles.includes(r))) {
						return false;
					}
					if (selectedTags.length) {
						if (!selectedTags.every(tag => user.tags.some(t => t.id === tag.id))) {
							return false;
						}
					}
					return true;
				})
				.sort(function (a, b) {
					if (field === 'email') {
						return a[field].localeCompare(b[field]) * mult;
					}
					if (field === 'status') {
						return (statusScoreFn(a) - statusScoreFn(b)) * mult;
					}
					if (field === 'createdAt') {
						const scoreA = new Date(a.createdAt).getTime();
						const scoreB = new Date(b.createdAt).getTime();
						return (scoreA - scoreB) * mult;
					}
				});
		},
		displayedUsers() {
			return this.filteredUsers.slice(this.displayStart, this.displayEnd);
		},
		filtersI18n() {
			return {
				locale: this.$i18n.locale,
				messages: this.$i18n.getLocaleMessage(this.$i18n.locale).filters,
			};
		},
		rolesList() {
			return this.roles.map(role => ({ value: role.id, label: role.name }));
		},
		tagsList() {
			return this.users.reduce(function (acc, user) {
				if (!user.tags?.length) {
					return acc;
				}
				const tags = user.tags.map(t => ({ ...t })).filter(t => !acc.some(a => a.id === t.id));
				acc.push(...tags);
				return acc;
			}, []);
		},
		canEditUsers() {
			return this.selectedUsers.length && (this.canChangeUser || this.canChangeUserRoles);
		},
		canArchiveUsers() {
			return (
				this.selectedUsers.length && this.canChangeUser && this.selectedUsers.every(user => !user.isArchived)
			);
		},
		canRestoreUsers() {
			return this.selectedUsers.length && this.selectedUsers.every(user => user.isArchived) && this.canChangeUser;
		},
		canDeleteUsers() {
			return this.selectedUsers.length && this.selectedUsers.every(user => user.isArchived) && this.canDeleteUser;
		},
	},
	methods: {
		userNumber(user) {
			return this.users.indexOf(user) + 1;
		},
		userStatusScore(user) {
			switch (user.status) {
				case 'ARCHIVED':
					return 3;
				case 'INACTIVE':
					return 2;
				case 'ACTIVE':
					return 1;
				default:
					return 0;
			}
		},
		async bulkCreateUsers(userData) {
			try {
				const { data } = await this.$apollo.mutate({
					mutation: BULK_CREATE_MUTATION,
					variables: { userData },
				});
				if (data.mutation.success) {
					this.users = [...data.mutation.users, ...this.users];
					const emails = data.mutation.users.map(u => u.email).join(', ');
					this.showToast(this.$t('bulkCreateSuccess', { emails }), 'toastSuccess', 'success');
					this.isLoadFromFileModalVisible = false;
				} else {
					throw new QueryError(data.mutation.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.handleError(err.cause);
				} else {
					this.handleError({ type: 'FallbackError' });
				}
			}
		},
		async runBulkMutation({ mutation, variables }) {
			try {
				const { data } = await this.$apollo.mutate({
					mutation,
					variables: { uids: this.selectedUsers.map(usr => usr.id), ...variables },
				});
				if (data.mutation.success) {
					return data.mutation.users;
				} else {
					throw new QueryError(data.mutation.error);
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.handleError(err.cause);
				} else {
					this.handleError({ type: 'FallbackError' });
				}
			}
		},
		async archiveUsers() {
			const users = await this.runBulkMutation({
				mutation: BULK_UPDATE_MUTATION,
				variables: { data: { status: 'ARCHIVED' } },
			});
			if (!users) {
				return;
			}
			this.mergeUsers(users);
			const emails = users.map(u => u.email).join(', ') ?? '';
			this.showToast(this.$t('bulkArchiveSuccess', { emails }), 'toastSuccess', 'success');
			this.selectedUsers = [];
		},
		async restoreUsers() {
			const users = await this.runBulkMutation({
				mutation: BULK_RESTORE_MUTATION,
			});
			if (!users) {
				return;
			}
			this.mergeUsers(users);
			const emails = users.map(u => u.email).join(', ') ?? '';
			this.showToast(this.$t('bulkRestoreSuccess', { emails }), 'toastSuccess', 'success');
			this.selectedUsers = [];
		},
		async deleteUsers() {
			await this.runBulkMutation({ mutation: BULK_DELETE_MUTATION });
			this.users = this.users.filter(usr => !this.selectedUsers.includes(usr));
			this.showToast(this.$t('bulkDeleteSuccess'), 'toastSuccess', 'success');
			this.selectedUsers = [];
		},
		async editUsers(userData) {
			if (this.selectedUsers.length === 0) {
				await this.bulkCreateUsers([userData]);
				this.isEditUserModalVisible = false;
				return;
			}
			if (this.selectedUsers.length === 1) {
				try {
					const { data } = await this.$apollo.mutate({
						mutation: UPDATE_MUTATION,
						variables: { uid: this.selectedUsers[0].id, data: userData },
					});
					if (data.mutation.success) {
						this.mergeUsers([data.mutation.user]);
						this.showToast('updateSuccess', 'toastSuccess', 'success');
					} else {
						throw new QueryError(data.mutation.error);
					}
				} catch (err) {
					if (err instanceof QueryError) {
						this.handleError(this.cause);
					} else {
						this.handleError({ type: 'FallbackError' });
					}
				}
			} else {
				const users = await this.runBulkMutation({
					mutation: BULK_UPDATE_MUTATION,
					variables: { data: userData },
				});
				if (!users) {
					return;
				}
				this.mergeUsers(users);
				this.showToast('bulkUpdateSuccess', 'toastSuccess', 'success');
			}

			this.selectedUsers = [];
			this.isEditUserModalVisible = false;
		},
		mergeUsers(users) {
			this.users = this.users.map(function (usr) {
				const user = users.find(u => u.id === usr.id);
				return user ?? usr;
			});
		},
		handleError(err) {
			let errorMessage = `errors.${err.type}`;
			switch (err.type) {
				case 'NotFoundError':
					errorMessage += `.${err.entity}`;
					break;
				case 'UnexpectedLengthError':
				case 'UnexpectedSymbolError':
				case 'UniqueValueError':
				case 'EmptyValueError':
					errorMessage += `.${err.field}`;
					break;
			}
			const translatedMessage = this.$t(errorMessage, err);
			this.showToast(translatedMessage, 'toastError', 'danger');
		},
		selectUser(user) {
			this.lastSelectedUser = user;
			if (this.selectedUsers.includes(user)) {
				this.selectedUsers = this.selectedUsers.filter(u => u !== user);
			} else {
				this.selectedUsers.push(user);
			}
		},
		selectAll(user) {
			if (!this.lastSelectedUser) {
				return this.selectUser(user);
			}
			const lastSelected = this.lastSelectedUser;
			const lastSelectedIndex = this.displayedUsers.indexOf(lastSelected);
			const selectedIndex = this.displayedUsers.indexOf(user);
			const from = Math.min(lastSelectedIndex, selectedIndex);
			const to = Math.max(lastSelectedIndex, selectedIndex);
			const affected = this.displayedUsers.slice(from, to + 1);
			affected.filter(u => u !== lastSelected).forEach(usr => this.selectUser(usr));
			this.lastSelectedUser = user;
		},
		cycleSort(field) {
			const sortBy = this.sortBy;

			if (sortBy.field !== field) {
				sortBy.field = field;
				sortBy.direction = 'asc';
			} else {
				if (sortBy.direction === 'asc') {
					return (sortBy.direction = 'desc');
				}
				if (sortBy.direction === 'desc') {
					sortBy.field = null;
					sortBy.direction = null;
				}
			}
		},
		getSortIcon(field) {
			const dir = this.sortBy.field === field ? this.sortBy.direction : null;
			switch (dir) {
				case 'asc':
					return 'fas fa-sort-down';
				case 'desc':
					return 'fas fa-sort-up';
				default:
					return 'fas fa-sort';
			}
		},
		changePage(page, bounds) {
			this.displayStart = bounds[0];
			this.displayEnd = bounds[1];
		},
		openEditModal(clearSelected) {
			if (clearSelected) {
				this.selectedUsers = [];
			}
			this.isEditUserModalVisible = true;
		},
	},
	apollo: {
		users: {
			query: USERS_QUERY,
			manual: true,
			fetchPolicy: 'cache-and-network',
			result({ loading, data }) {
				if (!loading) {
					this.users = data.myCompany.users;
					this.defaultRole = data.myCompany.defaultRole;
					this.roles = data.myCompany.roles;
					this.allTags = data.myCompany.allTags.slice(0);
					this.userLimit = data.myCompany.userLimit;
				}
			},
		},
	},
};
</script>
