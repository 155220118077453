<template>
	<div v-if="show" class="spinner">
		<div>
			<div class="spinner-size spinner-border text-secondary" role="status">
				<span class="visually-hidden">{{ $t('loading') }}</span>
			</div>
		</div>
	</div>
</template>

<i18n>
{
    "ru": {
        "loading": "Загрузка..."
    },
    "en": {
        "loading": "Loading..."
    }
}
</i18n>

<script>
export default {
	props: {
		show: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.spinner {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;

	& > div {
		width: min-content;
		position: relative;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
	}

	&-size {
		width: 6rem;
		height: 6rem;
	}
}
</style>
